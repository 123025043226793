<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage"/>
    <a-card :bordered="false" v-else>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-item label="出库单号">
                <a-input v-model.trim="queryParam.sell_num" placeholder="根据出库单号搜索" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="出货时间">
                <a-range-picker @change="onDateChange"/>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="货名">
                <a-select
                  showSearch
                  label-in-value
                  allowClear
                  placeholder="请选择货名"
                  style="width: 100%; z-index: 2;"
                  :value="goodInfo"
                  :filter-option="false"
                  :not-found-content="fetching2 ? undefined : null"
                  @search="getGoodInfo"
                  @change="changeGoodInfo"
                  mode="multiple"
                >
                  <template v-if="fetching2" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in goodOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="5">
              <a-form-item label="交易号">
                <a-input v-model.trim="queryParam.trx_num" placeholder="输入交易号进行搜索" />
              </a-form-item>
            </a-col>
            <a-col :span="1">
              <a-form-item>
                <a @click="toggle"><a-icon :type="expand ? 'up' : 'down'" /></a>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="订单状态">
                <a-select
                  allowClear
                  placeholder="请选择订单状态"
                  v-model="queryParam.order_status"
                  @change="queryList"
                >
                  <a-select-option v-for="d in orderStatusData" :key="d.value">{{ d.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="订单类型">
                <a-select
                  placeholder="请选择订单类型"
                  style="width: 100%"
                  v-model="queryParam.order_types"
                  allowClear
                  mode="multiple"
                >
                  <a-select-option v-for="op in orderTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="订购人">
                <a-input v-model.trim="queryParam.order_person" placeholder="请输入订购人搜索" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="送货信息">
                <a-input v-model.trim="queryParam['address_info']" placeholder="输入送货信息搜索" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="送货方式">
                <a-select
                  allowClear
                  placeholder="请选择送货方式"
                  style="width: 100%"
                  v-model="queryParam['escort_mode']"
                  @change="queryList"
                >
                  <a-select-option v-for="op in escortModeOps" :key="op.id">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="购买单位">
                <a-select
                  showSearch
                  label-in-value
                  allowClear
                  placeholder="请选择委托客户"
                  style="width: 100%"
                  :value="customerInfo"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentCustomer"
                  @change="changeCustomerInfo"
                  mode="multiple"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in buyerOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="备注">
                <a-input v-model.trim="queryParam.remark" placeholder="根据备注信息进行搜索" allowClear />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="发货状态">
                <a-select
                  placeholder="请选择发货状态"
                  style="width: 100%"
                  v-model="queryParam.shipment_status"
                  allowClear
                  @change="queryList"
                >
                  <a-select-option v-for="op in shipmentStatusOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="分配状态">
                <a-select
                  placeholder="请选择分配状态"
                  style="width: 100%"
                  v-model="queryParam['distr_identify']"
                  allowClear
                  @change="queryList"
                >
                  <a-select-option v-for="op in distrIdentifyTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="推荐人">
                <a-input v-model.trim="queryParam.recommender" placeholder="根据推荐人进行搜索" allowClear />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="快递单号">
                <a-input v-model.trim="queryParam.courier_no" placeholder="根据快递单号进行搜索" allowClear />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan" :style="{ display: expand ? 'block' : 'none' }">
              <a-form-item label="打包销售">
                <a-select
                  placeholder="是否含有打包销售产品"
                  style="width: 100%"
                  v-model="queryParam.is_package_sell"
                  allowClear
                  @change="queryList"
                >
                  <a-select-option :value="1">是</a-select-option>
                  <a-select-option :value="0">否</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" icon="search" @click="queryList">查询</a-button>
        <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
        <a-button type="query" :disabled="!selectedRowKeys.length" @click="handleAuditShipment()">审核发货</a-button>
        <a-button type="query" :disabled="!selectedRowKeys.length" @click="handleVerifyOrAudit('unAuditShipmentStatus')">反审核发货</a-button>
        <a-popover
          v-model="popVisible"
          title="选择月份"
          trigger="click"
        >
          <a slot="content">
            <p><a-month-picker v-model="exportDate" style="width: 200px;" format="YYYY/MM" placeholder="请选择月份" /></p>
            <p style="text-align: right;"><a-button type="primary" @click="exportLogisticsSheet">确定</a-button></p>
          </a>
          <a-button type="primary">物流清单</a-button>
        </a-popover>
        <a-upload
          :customRequest="handleCourierInfoImport"
          :showUploadList="false">
          <a-button type="primary">导入快递信息</a-button>
        </a-upload>
        <a-button type="query" :disabled="!selectedRowKeys.length" @click="distrAddressGood()">分配地址</a-button>
        <a-button type="query" :disabled="!selectedRowKeys.length" @click="unDistrAddressGood()">取消分配</a-button>
        <a-button type="danger" :disabled="!selectedRowKeys.length" @click="cancelSell()">退货</a-button>
        <a-button type="danger" :disabled="!selectedRowKeys.length" @click="unCancelSell()">取消退货</a-button>
        <a-popover
          v-model="pop2Visible"
          title="选择月份"
          trigger="click"
        >
          <a slot="content">
            <p><a-range-picker @change="onDateChange" style="width: 300px;"/></p>
            <p style="text-align: right;"><a-button type="primary" @click="exportProductTraceability">确定</a-button></p>
          </a>
          <a-button type="query">商品追溯</a-button>
        </a-popover>
      </div>

      <s-table
        ref="sellTable"
        size="small"
        rowKey="id"
        :scroll="{ x: 'max-content', y: 450 }"
        :columns="columns"
        :data="loadData"
        :alert="{
          show: true,
          msg: showTotal
        }"
        :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
        @dblclick="handleEdit"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="orderStatus" slot-scope="text, record">
          <a-tag :color="text === 1 ? '#4cd964' : '#ff4d4f'">
            {{ record['order_status_str'] }}
          </a-tag>
        </span>
        <span slot="goodName" slot-scope="text">
          <a-popover placement="bottom">
            <template v-if="text && text.length > 0">
              <span style="color: #FAAD14">{{ text.length !== 0 ? ` (${text.length})单 ` : null }}</span>
              <span>{{ text[0] }}</span>
            </template>
            <template slot="content">
              <span v-for="(item, i) in text" :key="i" style="display:block">{{ item }}</span>
            </template>
          </a-popover>
        </span>
        <span slot="cashRecord" slot-scope="text">
          <span v-for="item in text" :key="item.id" style="display:block">{{ getCashRecordInfo(item) }}</span>
        </span>
        <span slot="action" slot-scope="text, record, index">
          <template>
            <a @click="handleEdit(record, index)">编辑</a>
            <a-divider type="vertical" />
            <a @click="printSaleSellSheet(record)">销售出库单</a>
          </template>
        </span>
        <span slot="shipmentItem" slot-scope="text, record">
          <a-tag :color="text === 1 ? '#4cd964' : '#faad14'">
            {{ record['shipment_status_str'] }}
          </a-tag>
        </span>
        <span slot="distrIdentifySlot" slot-scope="text, record">
          <a-tag :color="record['distr_identify'] === 'DISTR' ? '#4cd964' : '#faad14'">
            {{ record['distr_identify_str'] }}
          </a-tag>
        </span>
        <span slot="courierInfoList" slot-scope="text">
          <span v-for="item in text" :key="item.id" style="display: block">
            快递单号：{{ item.courier_no }} / 发货日期: {{ moment(item.send_good_date).format('YYYY/MM/DD') }} /
            快递费：{{ item.courier_fee }} / 保险费：{{ item.safe_fee }} / 包装费：{{ item.package_fee }} /
            包裹数量：{{ item.package_num }} / 计费重量：{{ item.weight }}
          </span>
        </span>
        <span slot="orderAddressInfo" slot-scope="text">
          <span v-for="(item, i) in text" :key="i" style="display:block">
            {{ item['escort_mode_str'] }}/{{ item['address_str'] }}
          </span>
        </span>
      </s-table>

      <a-modal
        :visible="shipmentVisible"
        :confirmLoading="shipmentLoading"
        title="审核发货"
        :width="720"
        @cancel="handleShipmentCancel"
        @ok="handleShipmentSubmit"
      >
        <a-form-model layout="vertical" :model="shipmentForm" ref="shipmentForm">
          <a-row :gutter="24">
            <a-divider orientation="left">订单信息</a-divider>
            <a-col :span="8">
              <a-form-model-item label="出库单号">
                <a-input v-model="shipmentForm.sell_num" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="购买单位">
                <a-input v-model="shipmentForm.buyer_name" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="收货人">
                <a-input v-model="shipmentForm.receiving_person" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="16">
              <a-form-model-item label="收货地址">
                <a-input v-model="shipmentForm.receiving_address_name" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-divider orientation="left">货物信息
            </a-divider>
            <a-col :span="24">
              <edit-table
                :columns="goodSendColumns"
                :sourceData="goodSendInfos"
                @getTableDate="getGoodSendTableDate"
                :operate="{ add: false, del: false, alldel: false }"
                :disabled="true"></edit-table>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>

      <a-modal
        :visible="printSaleSellSheetVisible"
        :confirmLoading="printSaleSellSheetLoading"
        :width="1250"
        :maskClosable="false"
        title="销售出库单"
        @cancel="printSaleSellSheetVisible = false">
        <div id="printSaleSellSheetBill">
          <SaleSellSheetBill
            :currentData="currentData"
            :balanceData="balanceData">
          </SaleSellSheetBill>
        </div>
        <template slot="footer">
          <a-button key="submit"
                    type="primary"
                    id="printSaleSellSheetBillBtn"
                    v-print="printSaleSellSheetBill">确认打印</a-button>
          <a-button type="primary" @click="printSaleSellSheetVisible = false">关闭</a-button>
        </template>
      </a-modal>

      <a-modal
        :visible="visible"
        :confirmLoading="loading"
        title="分配地址送货"
        :width="800"
        @cancel="handleDistrAddressGoodCancel"
        @ok="handleDistrAddressGoodSubmit"
      >
        <div v-for="(item, index) in currentObj['order_address_relation_list']" :key="index">
          <a-divider orientation="left">
            <a-tag color="#f50">地址信息:</a-tag>
            <span style="font-weight:bold">{{ item.address_str != ''?item.address_str:item.escort_mode_str }}</span>
          </a-divider>
          <a-table
            size="small"
            :row-key="record => record.id"
            :scroll="{ x: 'max-content' }"
            :columns="distrGoodColumns"
            :data-source="item['distr_good_data']"
            :pagination="false"
          >
            <template slot="distrNumSlot" slot-scope="text, record, goodIdx">
              <a-input-number :disabled="record.num-record.distr_good_num===0" :value="text" @change="e => distrNumChange(e, record, index, goodIdx)"></a-input-number>
            </template>
            <template slot="distrRemarkSlot" slot-scope="text, record, goodIdx">
              <a-input :disabled="record.num-record.distr_good_num===0" :value="text" @blur="e => distrRemarkChange(e, record, index, goodIdx)" ></a-input>
            </template>
          </a-table>
        </div>
      </a-modal>

      <a-modal
        :visible="cancelSellVisible"
        :confirmLoading="cancelSellLoading"
        title="退货"
        :width="800"
        @cancel="cancelSellCancel"
        @ok="cancelSellSubmit"
      >
        <a-form-model
          layout="vertical"
          ref="cancelSellForm"
          :model="cancelSellForm"
        >
          <a-row :gutter="24">
            <a-divider orientation="left">订单信息</a-divider>
            <a-col :span="8">
              <a-form-model-item label="出库单号">
                <a-input v-model="cancelSellForm['sell_num']" disabled />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-divider orientation="left">货物信息</a-divider>
            <edit-table
              :columns="cancelSellColumns"
              :sourceData="cancelSellData"
              :operate="{ add: false, del: false, alldel: false }"
              :disabled="true"
            ></edit-table>
          </a-row>
        </a-form-model>
      </a-modal>
    </a-card>
  </keep-alive>
</template>
<script>
import { STable, EditTable } from '@/components'
import { getFirstLetter } from '@/utils/util'
import {
  getSellPage,
  cancelSell,
  unCancelSell,
  getGoodInfo,
  auditShipment,
  getGoodDetailBySellId,
  handleGoodOutShipment,
  exportLogisticsSheet,
  handleProductTraceability,
  importCourierInfo,
  sellAllTotal,
  initSellData,
  distrAddressGood,
  unDistrAddressGood,
  getPrintSellOrder
} from '@/api/wms/sale'
import { getCustomer, getSupplier, downloadExcel } from '@/api/common'
import SaleSellSheetBill from '@/views/wms/sale/bill/SaleSellSheetBill'
import debounce from 'lodash/debounce';
import moment from 'moment'

export default {
  components: {
    STable,
    SaleSellSheetBill,
    EditTable
  },
  data() {
    this.lastFetchId = 0;
    this.lastFetchId2 = 0;
    this.lastFetchId3 = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    this.getGoodInfo = debounce(this.getGoodInfo, 800);
    return {
      queryParam: {}, // 搜索条件对象
      buyerOps: [], // 购买单位
      supplierOps: [], // 供应商
      goodOps: [], // 商品信息
      customerInfo: undefined,
      goodInfo: undefined,
      fetching: false,
      fetching2: false,
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          fixed: `left`,
          width: 50
        },
        {
          title: '出库单号',
          dataIndex: 'sell_num',
          // fixed: `left`,
          width: 150
        },
        {
          title: '发货状态',
          dataIndex: 'shipment_status',
          scopedSlots: { customRender: 'shipmentItem' },
          width: 80
        },
        {
          title: '货物信息',
          dataIndex: 'good_name',
          scopedSlots: { customRender: 'goodName' },
          width: 550
        },
        {
          title: '送货方式',
          dataIndex: 'escort_mode_str',
          width: 120
        },
        {
          title: '订单类型',
          dataIndex: 'order_type_str',
          width: 80
        },
        {
          title: '订单状态',
          dataIndex: 'order_status',
          scopedSlots: { customRender: 'orderStatus' },
          width: 80
        },
        {
          title: '出货时间',
          dataIndex: 'out_date',
          width: 120
        },
        {
          title: '购买单位',
          dataIndex: 'buyer_name',
          width: 200
        },
        {
          title: '地址信息',
          dataIndex: 'order_address_relation_list',
          scopedSlots: { customRender: 'orderAddressInfo' },
          width: 400
        },
        {
          title: '快递信息',
          dataIndex: 'courier_info_list',
          scopedSlots: { customRender: 'courierInfoList' },
          width: 400
        },
        {
          title: '现金收费',
          dataIndex: 'cash_record_list',
          scopedSlots: { customRender: 'cashRecord' },
          width: 350
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 200
        },
        {
          title: '录入人',
          dataIndex: 'seller_name',
          width: 60
        },
        {
          title: '录入时间',
          dataIndex: 'created_at',
          width: 120
        },
        {
          title: '分配状态',
          dataIndex: 'distr_identify_str',
          scopedSlots: { customRender: 'distrIdentifySlot' },
          width: 80
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: `right`,
          scopedSlots: { customRender: 'action' },
          width: 150
        }
      ],
      selectedRowKeys: [],
      selectedRows: [],
      loadData: parameter => {
        return getSellPage(Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      showTotal: '数量：0; 金额：0',
      confirmLoading: false,
      currentData: {},
      balanceData: [],
      shipmentVisible: false,
      shipmentLoading: false,
      shipmentForm: {
        sell_id: null,
        sell_num: null,
        buyer_name: null,
        receiving_person: null,
        receiving_address_name: null
      },
      goodSendInfos: [],
      goodSendColumns: [
        {
          title: '货物名称',
          dataIndex: 'good_name',
          extra: {
            type: `text`
          }
        },
        {
          title: '货物规格',
          dataIndex: 'good_spec',
          extra: {
            type: `text`
          }
        },
        {
          title: '购买数量',
          dataIndex: 'num',
          extra: {
            type: `text`
          }
        },
        {
          title: '已发货数量',
          dataIndex: 'send_num',
          extra: {
            type: `text`
          }
        },
        {
          title: '本次发货数量',
          dataIndex: 'last_send_num'
        }
      ],
      printSaleSellSheetVisible: false,
      printSaleSellSheetLoading: false,
      printSaleSellSheetBill: {
        id: `printSaleSellSheetBill`,
        popTitle: ``,
        extraCss: ``,
        extraHead: `<meta http-equiv="Content-Language" content="zh-cn" />`
      },
      escortModeOps: [], // 送货方式
      popVisible: false,
      pop2Visible: false,
      exportDate: null,
      orderStatusData: [], // 订单状态
      orderTypeOps: [], // 订单类型
      shipmentStatusOps: [], // 发货状态
      distrIdentifyTypeOps: [], // 分配标识状态
      expand: true, // 默认撑开
      currentObj: {},
      visible: false,
      loading: false,
      distrGoodColumns: [
        {
          title: '货物名称',
          dataIndex: 'good_name'
        },
        {
          title: '货物规格',
          dataIndex: 'good_spec'
        },
        {
          title: '出货数量',
          dataIndex: 'num'
        },
        {
          title: '已分配数量',
          dataIndex: 'distr_good_num'
        },
        {
          title: '分配数量',
          dataIndex: 'distr_num',
          scopedSlots: { customRender: 'distrNumSlot' }
        },
        {
          title: '备注',
          dataIndex: 'distr_remark',
          scopedSlots: { customRender: 'distrRemarkSlot' }
        }
      ],
      cancelSellVisible: false,
      cancelSellLoading: false,
      cancelSellForm: {},
      cancelSellColumns: [
        {
          title: '货物名称',
          dataIndex: 'good_name',
          extra: {
            type: `text`
          }
        },
        {
          title: '货物规格',
          dataIndex: 'good_spec',
          extra: {
            type: `text`
          }
        },
        {
          title: '出货数量',
          dataIndex: 'num',
          extra: {
            type: `text`
          }
        },
        {
          title: '已退货数量',
          dataIndex: 'return_good_num',
          extra: {
            type: `text`
          }
        },
        {
          title: '剩余未退货数量',
          dataIndex: 'last_return_good_num'
        }
      ],
      cancelSellData: []
    }
  },
  watch: {
    $route: function(newRoute) {
      if (newRoute.name === 'Sell' && this.queryParam) {
        newRoute.meta.title = '出货'
        this.$nextTick(_ => {
          this.$refs.sellTable.refresh(newRoute.query.isEdit)
        })
      }
    }
  },
  mounted() {
    getSupplier({ department: 8 }).then(res => {
      this.supplierOps = res
    })
    initSellData().then(res => {
      this.orderTypeOps = res.WMS_ORDER_TYPE
      this.orderStatusData = res.WMS_ORDER_STATUS
      this.escortModeOps = res.ESCORT_MODE
      this.shipmentStatusOps = res.SHIPMENT_STATUS
      this.distrIdentifyTypeOps = res.DISTR_IDENTIFY_TYPE
    })
    this.sellAllTotal()
  },
  methods: {
    moment,
    cancelSell() {
      if (this.selectedRowKeys.length !== 1) {
        this.$notification['error']({
          message: '提示',
          description: '退货同时只能选择一个订单'
        })
        return
      }
      this.cancelSellForm = Object.assign({}, this.selectedRows[0])
      if (this.cancelSellForm['order_status'] === 2) {
        this.$notification['error']({
          message: '提示',
          description: `该订单${this.cancelSellForm['sell_num']}已退货`
        })
        return
      }
      getGoodDetailBySellId({ 'sellId': this.cancelSellForm['id'] }).then(res => {
        this.cancelSellData = res
        this.cancelSellVisible = true
      })
    },
    cancelSellSubmit() {
      this.cancelSellLoading = true
      const reqParam = {
        'id': this.cancelSellForm['id'],
        'return_good_list': this.cancelSellData
      }
      cancelSell(reqParam).then(res => {
        this.$notification['success']({
          message: '提示',
          description: '操作成功'
        })
        this.$refs.sellTable.refresh(false)
        this.$refs.sellTable.clearSelected()
        this.cancelSellCancel()
      })
    },
    cancelSellCancel() {
      this.cancelSellVisible = false
      this.cancelSellLoading = false
      this.cancelSellForm = {}
      this.cancelSellData = []
    },
    unCancelSell() {
      if (this.selectedRowKeys.length !== 1) {
        this.$notification['error']({
          message: '提示',
          description: '取消退货同时只能选择一个订单'
        })
        return
      }
      const current = this.selectedRows[0]
      if (current['order_status'] !== 2 && current['order_status'] !== 4) {
        this.$notification['error']({
          message: '提示',
          description: '只有退货或部分退货的订单才能取消退货'
        })
        return
      }
      this.$confirm({
        title: '提示',
        content: '确认要取消退货吗?',
        onOk: () => {
          unCancelSell({ 'id': this.selectedRows[0]['id'] }).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
          }).finally(() => {
            this.$refs.sellTable.refresh(false)
            this.$refs.sellTable.clearSelected()
          })
        }
      })
    },
    distrAddressGood() {
      if (this.selectedRowKeys.length !== 1) {
        this.$notification['warn']({
          message: '提示',
          description: '分配地址送货同时只能选择一个订单'
        })
        return
      }

      this.currentObj = Object.assign({}, this.selectedRows[0])
      if (this.currentObj['distr_identify'] === 'DISTR') {
        this.$notification['warn']({
          message: '提示',
          description: '出货单已经分配过地址'
        })
        return
      }

      const addressData = this.currentObj['order_address_relation_list']
      if (addressData.length <= 0) {
        this.$notification['warn']({
          message: '提示',
          description: '订单还未添加送货地址'
        })
        return
      }

      this.visible = true
    },
    handleDistrAddressGoodSubmit() {
      this.loading = true
      const items = []
      for (const addressObj of this.currentObj['order_address_relation_list']) {
        const goodItems = []
        for (const goodObj of addressObj['distr_good_data']) {
          goodItems.push({
            'bill_good_id': goodObj['id'],
            'distr_num': goodObj['distr_num'],
            'distr_remark': goodObj['distr_remark']
          })
        }
        items.push({
          'relation_id': addressObj['id'],
          'good_items': goodItems
        })
      }
      const reqParam = {
        'bill_id': this.currentObj['id'],
        'items': items
      }
      distrAddressGood(reqParam).then(res => {
        this.$notification['success']({
          message: '提示',
          description: '操作成功'
        })
        this.visible = false
        this.$refs.sellTable.refresh(false)
        this.$refs.sellTable.clearSelected()
      }).finally(() => {
        this.loading = false
      })
    },
    handleDistrAddressGoodCancel() {
      this.visible = false
      this.loading = false
    },
    distrNumChange(value, record, index, goodIdx) {
      this.currentObj['order_address_relation_list'][index]['distr_good_data'][goodIdx]['distr_num'] = value
    },
    distrRemarkChange(e, record, index, goodIdx) {
      this.currentObj['order_address_relation_list'][index]['distr_good_data'][goodIdx]['distr_remark'] = e.target.value
    },
    unDistrAddressGood() {
      if (this.selectedRowKeys.length !== 1) {
        this.$notification['warn']({
          message: '提示',
          description: '取消分配同时只能选择一个订单'
        })
        return
      }

      this.$confirm({
        title: '确认提示',
        content: '确定要取消分配吗？',
        onOk: () => {
          unDistrAddressGood({ 'bill_id': this.selectedRows[0]['id'] }).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
          }).finally(() => {
            this.$refs.sellTable.refresh(false)
            this.$refs.sellTable.clearSelected()
          })
        }
      })
    },
    toggle() {
      this.expand = !this.expand
    },
    queryList() {
      this.$refs.sellTable.refresh(true)
      this.sellAllTotal()
    },
    sellAllTotal() {
      sellAllTotal(this.queryParam).then(res => {
        this.showTotal = `数量：${res.total}; 金额：${res.money}`
      })
    },
    handleCourierInfoImport(param) {
      const formData = new FormData()
      formData.append('uploadFile', param.file)
      importCourierInfo(formData).then(v => {
        param.onSuccess()
        this.$notification['success']({
          message: '提示',
          description: '导入成功'
        })
      })
    },
    getGoodInfo(value) {
      this.lastFetchId2 += 1;
      const fetchId = this.lastFetchId2;
      this.goodOps = [];
      this.fetching2 = true;
      getGoodInfo({
        goodName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId2) {
          return;
        }
        this.goodOps = res;
        this.fetching2 = false;
      })
    },
    changeGoodInfo(value) {
      this.goodInfo = value;
      const ids = [];
      if (value.length !== 0) {
        value.forEach(v => ids.push(v.key));
      }
      this.queryParam['good_ids'] = ids;
      this.goodOps = [];
      this.fetching2 = false;
    },
    getCashRecordInfo(record) {
      let result = ''
      if (record.cash_status === 1) {
        result = `已完成 交易号:${record.trx_id} 付款时间:${record.pay_date}`
      }
      if (record.cash_status === 0) {
        if (record.is_expire === 1) {
          result = '已过期'
        } else {
          result = '待支付'
        }
      }
      return result.concat(`合计:${record.amount}`)
    },
    // 销售出库单
    printSaleSellSheet(record) {
      if (record['order_status'] === 2 || record['order_status'] === 4) {
        this.$notification['error']({
          message: '提示',
          description: '订单已退货或部分退货'
        })
        return
      }
      getPrintSellOrder(record.id).then(res => {
        this.balanceData = res.list;
        this.currentData = record;
        this.currentData['agencyFee'] = res['agencyFee']
        this.currentData['totalNum'] = res['totalNum']
        this.currentData['totalPrice'] = res['totalPrice']
        this.printSaleSellSheetVisible = true;
      })
    },
    handleCreate() {
      this.$router.replace({
        name: 'SellCUR',
        params: {
          id: 'create'
        }
      })
    },
    handleEdit(record) {
      this.$refs.sellTable.clearSelected()
      this.$router.replace({
        name: 'SellCUR',
        params: record
      })
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.buyerOps = [];
      this.fetching = true;
      getCustomer({
        department: 8,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.buyerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value;
      const ids = [];
      if (value.length !== 0) {
        value.forEach(item => ids.push(item.key));
      }
      this.queryParam['buyer_ids'] = ids;
      this.buyerOps = [];
      this.fetching = false;
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['start_date'] = startDate
        this.queryParam['end_date'] = endDate
      } else {
        this.queryParam['start_date'] = null
        this.queryParam['end_date'] = null
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          getFirstLetter(option.componentOptions.children[0].text).toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    handleAuditShipment() {
      if (this.selectedRowKeys.length !== 1) {
        this.$message.warning('审核发货同时只能选择一个订单')
        return
      }
      const record = this.selectedRows[0]
      this.shipmentForm.sell_id = record.id
      this.shipmentForm.sell_num = record.sell_num
      this.shipmentForm.buyer_name = record.buyer_name
      this.shipmentForm.receiving_person = record.receiving_person
      this.shipmentForm.receiving_address_name = record.receiving_address_name
      getGoodDetailBySellId({
        sellId: this.shipmentForm.sell_id
      }).then(res => {
        this.goodSendInfos = res
      })
      this.shipmentVisible = true;
    },
    getGoodSendTableDate(data) {
      this.goodSendInfos = data;
    },
    handleShipmentCancel() {
      this.shipmentVisible = false
      this.shipmentLoading = false
      this.shipmentForm.sell_id = null
      this.shipmentForm.sell_num = null
      this.shipmentForm.buyer_name = null
      this.shipmentForm.receiving_person = null
      this.shipmentForm.receiving_address_name = null
      this.goodSendInfos = []
    },
    handleShipmentSubmit() {
      this.shipmentLoading = true
      handleGoodOutShipment({
        sell_id: this.shipmentForm.sell_id,
        good_out_infos: this.goodSendInfos
      }).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
            this.handleShipmentCancel();
            this.selectedRows.forEach(v => {
              v.shipment_status = res;
            });
            this.$refs.sellTable.clearSelected()
            this.$refs.sellTable.refresh(false);
          }).finally(_ => {
            this.shipmentLoading = false;
          });
    },
    handleVerifyOrAudit(operate) {
      const idList = [];
      this.selectedRows.forEach(v => {
        switch (operate) {
          case 'auditShipmentStatus': // 审核
            if (v.shipment_status === 0) {
              idList.push(v.id);
            }
            break;
          case 'unAuditShipmentStatus': // 反审核
            if (v.shipment_status === 1 || v.shipment_status === 2) {
              idList.push(v.id);
            }
            break;
        }
      });

      if (idList.length) {
        auditShipment(operate, { ids: idList }).then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '操作成功'
          });
          this.$refs.sellTable.clearSelected()
          this.$refs.sellTable.refresh(false);
          // 操作成功后动态将选中的 row 数据更新方便操作
          this.selectedRows.forEach(v => {
            if (operate === 'auditShipmentStatus') {
              // 审核
              v.shipment_status = 1;
            } else {
              // 反审核
              v.shipment_status = 0;
            }
          });
        });
      } else {
        this.$message.warning(operate === 'auditShipmentStatus' ? '请选择未发货的信息' : '请选择已发货的信息');
      }
    },
    // 导出物流清单
    exportLogisticsSheet() {
      if (this.exportDate) {
        this.queryParam['year'] = this.exportDate.year()
        this.queryParam['month'] = this.exportDate.month() + 1
        exportLogisticsSheet(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible = false;
        })
      } else {
        this.$message.warning('请选择导出月份');
      }
    },
    exportProductTraceability() {
      handleProductTraceability(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.pop2Visible = false;
        })
    }
  }
}
</script>
